import Cookies from "js-cookie";
import React, { useEffect } from "react";

import S from "./CookieConsent.style";
import useCookieConsentData from "./useCookieConsentData";

const CookieConsent = () => {
  const cookieConsent = useCookieConsentData();
  const [isOpen, setIsOpen] = React.useState(true);
  const cookieValue = Cookies.get("cookieConsent");

  React.useEffect(() => setIsOpen(cookieValue !== "true"), [cookieValue]);

  const enableAnalytics = () => {
    Cookies.set("cookieConsent", "true"),
      {
        sameSite: "lax",
        secure: "true",
        expires: 365,
      };
    setIsOpen(false);
  };

  return isOpen && !cookieValue ? (
    <S.Container>
      {cookieConsent.paragraph && (
        <S.Paragraph>
          {cookieConsent.paragraph}
          {cookieConsent.link && (
            <S.A href={cookieConsent.link.url}>{cookieConsent.link.title}</S.A>
          )}
        </S.Paragraph>
      )}
      <S.Button onClick={enableAnalytics}>{cookieConsent.label}</S.Button>
    </S.Container>
  ) : null;
};

export default CookieConsent;
