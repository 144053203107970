import { paragraph } from "@/styles/typography";
import styled from "styled-components";

const Container = styled.div`
  padding: 22px;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
`;
const Paragraph = styled(paragraph)`
  font-size: 14px;
  margin: 0;
  text-align: left;
`;

const A = styled.a`
  margin-left: 5px;
  font-size: 14px;
  color: white;
  text-decoration: none;
  position: relative;
  display: inline-block;
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.textColors.secondary};
    bottom: 5%;
    transition: all 0.2s ease;
  }
  &:hover::after {
    bottom: 40%;
  }
`;

const Button = styled.button`
  font-weight: 600;
  margin-left: auto;
  border: none;
  color: white;
  background-color: ${({ theme }) => theme.colors.secondary};
  padding: 10px 20px;
  cursor: pointer;
`;

const S = {
  Container,
  Paragraph,
  A,
  Button,
};

export default S;
