import * as React from "react";
import { ThemeProvider } from "styled-components";

import Body from "@/components/core-blocks/Body";
import CookieConsent from "@/components/core-blocks/CookieConsent";
import Footer from "@/components/core-blocks/Footer";

import Header from "@/components/core-blocks/Header";
import Main from "@/components/core-blocks/Main";
import Seo, { ISeo } from "@/components/core-blocks/Seo";
import LocationProvider, {
  ILocationProvider,
} from "@/contexts/LocationProvider";
import Flexible, { IFlexible } from "@/src/imports/Flexible";
import GlobalStyle from "@/styles/global";
import { primary } from "@/styles/themes/primary.theme";
import { IPageSettings } from "../types";

export type IPageTemplate = {
  location: ILocationProvider;
  pageContext: {
    acf: IFlexible;
    seo: ISeo;
    pageSettings: IPageSettings;
  };
};

const PageTemplate: React.FC<IPageTemplate> = ({
  location: { href, pathname },
  pageContext: { seo, acf, pageSettings },
}) => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const hero = (acf.components || []).filter((c) => c.label === "MainHero");
  return (
    <ThemeProvider theme={primary}>
      <LocationProvider {...{ href, pathname }}>
        <GlobalStyle />
        <Seo {...seo} />
        <Body>
          <Header hero={!!hero.length} />
          <Main>
            <Flexible components={acf.components} />
            <Footer mobileFooterImage={pageSettings.mobileFooterImage} />
            <CookieConsent />
          </Main>
        </Body>
      </LocationProvider>
    </ThemeProvider>
  );
};

export default PageTemplate;
